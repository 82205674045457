import { Injectable } from '@angular/core';
import * as LD from 'launchdarkly-js-client-sdk';
import { SeEnvironmentUrlService } from 'se-fe-auth-support';
import { LaunchDarklyClient } from 'se-launchdarkly-js';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyService {
  constructor(private urlService: SeEnvironmentUrlService) {}
  private _client: LD.LDClient;

  get client(): LD.LDClient {
    return this._client;
  }

  public enabled(variation: string): boolean {
    return this.variation(variation, false);
  }

  public disabled(variation: string): boolean {
    return !this.variation(variation, false);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public variation(variation: string, defaultValue: any): boolean {
    if (!this._client) {
      console.warn('LDClient uninitialized, returning false');
    }
    return this._client ? this._client.variation(variation, defaultValue) : false;
  }

  public async ldClient(orgId: number): Promise<LD.LDClient> {
    try {
      this._client = await LaunchDarklyClient.client(
        environment.launchDarklyClientId,
        this.urlService.getApiUrl(),
        orgId
      );
    } catch (e) {
      console.error('LaunchDarkly client could not be created');
      console.error(e);
    }
    return this._client;
  }
}
