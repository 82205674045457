import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SeFeFrameRouterService } from 'se-fe-frame-router';
import { AccessDeniedComponent } from './core/components/access-denied/access-denied.component';
import { OrganizationResolver } from './core/resolvers/organization.resolver';
import { AppPermissionGuard } from './core/guards/app-permission.guard';
import { SeAuthGuardService } from 'se-fe-auth-support';
import { LaunchDarklyInitializer } from './core/resolvers/launch-darkly-initializer.resolver';

const routes: Routes = [
  { path: 'access-denied', component: AccessDeniedComponent },
  {
    path: '',
    data: {},
    canActivate: [SeFeFrameRouterService, SeAuthGuardService, AppPermissionGuard],
    resolve: { currentOrg: OrganizationResolver, ld: LaunchDarklyInitializer },
    children: [
      { path: '', redirectTo: 'payouts', pathMatch: 'full' },
      {
        path: 'payouts',
        loadChildren: () => import('./modules/payouts/payouts.module').then((m) => m.PayoutsModule),
      },
      {
        path: 'payouts/:payout_id',
        loadChildren: () => import('./modules/payout-detail/payout-detail.module').then((m) => m.PayoutDetailModule),
      },
      { path: 'refunds', loadChildren: () => import('./modules/refunds/refunds.module').then((m) => m.RefundsModule) },
      {
        path: 'sale-items',
        loadChildren: () => import('./modules/sale-items/sale-items.module').then((m) => m.SaleItemsModule),
      },
      {
        path: 'sale-items/:sale_item_id',
        loadChildren: () =>
          import('./modules/sale-item-detail/sale-item-detail.module').then((m) => m.SaleItemDetailModule),
      },
      {
        path: 'receivables',
        loadChildren: () => import('./modules/receivables/receivables.module').then((m) => m.ReceivablesModule),
      },
      {
        path: 'adjustments',
        loadChildren: () => import('./modules/adjustments/adjustments.module').then((m) => m.AdjustmentsModule),
      },
      {
        path: 'payments-received',
        loadChildren: () =>
          import('./modules/payments-received/payments-received.module').then((m) => m.PaymentsReceivedModule),
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
