import { Inject, Injectable } from '@angular/core';
import { LaunchDarklyService } from '../services/launch-darkly.service';
import { CURRENT_ORG_ID } from '../providers/current-org-id.provider';
import { Resolve } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { LDClient } from 'launchdarkly-js-client-sdk';

@Injectable({
  providedIn: 'root',
})
/**
 * Resolver to initialize LaunchDarkly client
 */
export class LaunchDarklyInitializer implements Resolve<LDClient> {
  constructor(
    @Inject(CURRENT_ORG_ID) protected currentOrgId: string,
    private launchDarklyService: LaunchDarklyService
  ) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  resolve(): Observable<LDClient> {
    return this.launchDarklyService.client ? of(this.launchDarklyService.client) : this.initClient();
  }

  private initClient(): Observable<LDClient> {
    return from(this.launchDarklyService.ldClient(Number(this.currentOrgId)));
  }
}
